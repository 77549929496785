//"""Token constants (from somewhere)."""

var __all__ = ["tok_name", "ISTERMINAL", "ISNONTERMINAL", "ISEOF"];

// #  This file is automatically generated; please don't muck it up!
// #
// #  To update the symbols in this file, 'cd' to the top directory of
// #  the python source tree after building the interpreter and run:
// #
// #    ./python Lib/token.py

// #--start constants--
var tokens = {
    T_ENDMARKER: 0,
    T_NAME: 1,
    T_NUMBER: 2,
    T_STRING: 3,
    T_NEWLINE: 4,
    T_INDENT: 5,
    T_DEDENT: 6,
    T_LPAR: 7,
    T_RPAR: 8,
    T_LSQB: 9,
    T_RSQB: 10,
    T_COLON: 11,
    T_COMMA: 12,
    T_SEMI: 13,
    T_PLUS: 14,
    T_MINUS: 15,
    T_STAR: 16,
    T_SLASH: 17,
    T_VBAR: 18,
    T_AMPER: 19,
    T_LESS: 20,
    T_GREATER: 21,
    T_EQUAL: 22,
    T_DOT: 23,
    T_PERCENT: 24,
    T_LBRACE: 25,
    T_RBRACE: 26,
    T_EQEQUAL: 27,
    T_NOTEQUAL: 28,
    T_LESSEQUAL: 29,
    T_GREATEREQUAL: 30,
    T_TILDE: 31,
    T_CIRCUMFLEX: 32,
    T_LEFTSHIFT: 33,
    T_RIGHTSHIFT: 34,
    T_DOUBLESTAR: 35,
    T_PLUSEQUAL: 36,
    T_MINEQUAL: 37,
    T_STAREQUAL: 38,
    T_SLASHEQUAL: 39,
    T_PERCENTEQUAL: 40,
    T_AMPEREQUAL: 41,
    T_VBAREQUAL: 42,
    T_CIRCUMFLEXEQUAL: 43,
    T_LEFTSHIFTEQUAL: 44,
    T_RIGHTSHIFTEQUAL: 45,
    T_DOUBLESTAREQUAL: 46,
    T_DOUBLESLASH: 47,
    T_DOUBLESLASHEQUAL: 48,
    T_AT: 49,
    T_ATEQUAL: 50,
    T_RARROW: 51,
    T_ELLIPSIS: 52,
    T_OP: 53,
    T_AWAIT: 54,
    T_ASYNC: 55,
    T_ERRORTOKEN: 56,

    //special cases
    T_NT_OFFSET: 256,
    T_N_TOKENS: 60,

    //taken from tokenize.py
    T_COMMENT: 57,
    T_NL: 58,
    T_ENCODING: 59
};
// #--end constants--

var EXACT_TOKEN_TYPES = {
    // "<>": tokens.T_NOTEQUAL, Only in py2
    "!=": tokens.T_NOTEQUAL,
    "%": tokens.T_PERCENT,
    "%=": tokens.T_PERCENTEQUAL,
    "&": tokens.T_AMPER,
    "&=": tokens.T_AMPEREQUAL,
    "(": tokens.T_LPAR,
    ")": tokens.T_RPAR,
    "*": tokens.T_STAR,
    "**": tokens.T_DOUBLESTAR,
    "**=": tokens.T_DOUBLESTAREQUAL,
    "*=": tokens.T_STAREQUAL,
    "+": tokens.T_PLUS,
    "+=": tokens.T_PLUSEQUAL,
    ",": tokens.T_COMMA,
    "-": tokens.T_MINUS,
    "-=": tokens.T_MINEQUAL,
    "->": tokens.T_RARROW,
    ".": tokens.T_DOT,
    "...": tokens.T_ELLIPSIS,
    "/": tokens.T_SLASH,
    "//": tokens.T_DOUBLESLASH,
    "//=": tokens.T_DOUBLESLASHEQUAL,
    "/=": tokens.T_SLASHEQUAL,
    ":": tokens.T_COLON,
    // ":=": tokens.T_COLONEQUAL, // currently not listed in tokens
    ";": tokens.T_SEMI,
    "<": tokens.T_LESS,
    "<<": tokens.T_LEFTSHIFT,
    "<<=": tokens.T_LEFTSHIFTEQUAL,
    "<=": tokens.T_LESSEQUAL,
    "=": tokens.T_EQUAL,
    "==": tokens.T_EQEQUAL,
    ">": tokens.T_GREATER,
    ">=": tokens.T_GREATEREQUAL,
    ">>": tokens.T_RIGHTSHIFT,
    ">>=": tokens.T_RIGHTSHIFTEQUAL,
    "@": tokens.T_AT,
    "@=": tokens.T_ATEQUAL,
    "[": tokens.T_LSQB,
    "]": tokens.T_RSQB,
    "^": tokens.T_CIRCUMFLEX,
    "^=": tokens.T_CIRCUMFLEXEQUAL,
    "{": tokens.T_LBRACE,
    "|": tokens.T_VBAR,
    "|=": tokens.T_VBAREQUAL,
    "}": tokens.T_RBRACE,
    "~": tokens.T_TILDE,
};

var tok_name = {};
(function() {
    for (var i in tokens) {
        tok_name[tokens[i]] = i;
    }
})();

__all__.concat(Object.keys(tok_name).map(function (k) { return tok_name[k]; }));

function ISTERMINAL(x) {
    return x < tokens.T_NT_OFFSET;
}

function ISNONTERMINAL(x) {
    return x >= tokens.T_NT_OFFSET;
}

function ISEOF(x) {
    return x == tokens.T_ENDMARKER;
}

Sk.token = {};
Sk.token.tokens = tokens;
Sk.token.tok_name = tok_name;
Sk.token.EXACT_TOKEN_TYPES = EXACT_TOKEN_TYPES;
Sk.token.ISTERMINAL = ISTERMINAL;
Sk.token.ISNONTERMINAL = ISNONTERMINAL;
Sk.token.ISEOF = ISEOF;

Sk.exportSymbol("Sk.token", Sk.token);
Sk.exportSymbol("Sk.token.tokens", Sk.token.tokens);
Sk.exportSymbol("Sk.token.tok_name", Sk.token.tok_name);
Sk.exportSymbol("Sk.token.EXACT_TOKEN_TYPES");
Sk.exportSymbol("Sk.token.ISTERMINAL", Sk.token.ISTERMINAL);
Sk.exportSymbol("Sk.token.ISNONTERMINAL", Sk.token.ISNONTERMINAL);
Sk.exportSymbol("Sk.token.ISEOF", Sk.token.ISEOF);
